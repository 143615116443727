import { PAYMENT_CONFIG, PAYMENT_FIELDS, PAYMENT_SELECTORS } from 'src/constants';
import { useApiState, useSpinner } from 'src/hooks';
import { useEffect, useState } from 'react';

const UNRECOVERABLE_ERROR = 'Something has gone wrong. Please pay with Bank account or refresh the page to try again';
const FORM_ERROR = 'Please fix the error(s) above';

export const DATA_LAYER_NAME = 'adobeDataLayer';

const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

const createPaymentForm = (config: any) => {
  // eslint-disable-next-line no-console
  const logger = (level: string, message: string) => console.log(message);
  const preFlowHook = (callback: any) => callback(config);

  return new Promise((resolve, reject) => {
    try {
      if (!window?.firstdata?.createPaymentForm) {
        throw new Error('Payeezy methods are not in the window');
      }

      window.firstdata.createPaymentForm(PAYMENT_CONFIG, { preFlowHook }, resolve, logger);
    } catch (error) {
      reject(error);
    }
  });
};

const destroyPaymentForm = (paymentForm: any) => {
  try {
    paymentForm.destroyFields();
  } catch { } // eslint-disable-line no-empty

  try {
    window.firstdata.createPaymentForm = undefined;
  } catch { } // eslint-disable-line no-empty
};

const purchaseComplete = (errorMessage: string) => {
  const serviceInfo = sessionStorage.getItem('serviceInfo');
  let serviceInformation = null;
  if (serviceInfo) {
    serviceInformation = JSON.parse(serviceInfo);
  }
  const billingInfo = sessionStorage.getItem('billingInfo');
  let billingInformation = null;
  if (billingInfo) {
    billingInformation = JSON.parse(billingInfo);
  }
  const checkout = {
    stepDescription: 'Payment Information',
    stepName: 'Step 3',
    stepStatus: 'Error'
  };
  pushCheckOutSteps(billingInformation, checkout, errorMessage, null, serviceInformation);
};

const pushCheckOutSteps = (billingInfo?: any, checkout?: any, errorMessage?: any, modal?: any, serviceInfo?: any) => push({

  billingInfo: billingInfo,
  checkout: checkout,
  event: 'checkoutSteps',
  global: {
    brand: getBrand(),
    eventAction: 'checkoutSteps'
  },
  messages: {
    error: errorMessage
  },
  modal: modal,
  serviceInfo: serviceInfo
});

export default ({ onSubmit }: any) => {
  const [ paymentForm, setPaymentForm ] = useState<any>({});
  const [ error, setError ] = useState('');

  const { paymentJs } = useApiState();
  const { hideSpinner, showSpinner } = useSpinner();

  useEffect(() => {
    paymentForm?.on?.('focus', () => {
      error === UNRECOVERABLE_ERROR || setError('');
    });
  }, [ error ]);

  const getPaymentJs = () => paymentJs ? Promise.resolve(paymentJs) : Promise.reject();

  const initializePaymentJs = () => {
    setError('');

    getPaymentJs()
      .then(createPaymentForm)
      .then((response: any) => (response?.error ? Promise.reject(response.error) : response))
      .then(setPaymentForm)
      .catch(() => { setError(UNRECOVERABLE_ERROR); purchaseComplete(UNRECOVERABLE_ERROR); });
  };

  const onPaymentJsSubmit = (data: any) => {
    showSpinner();

    const onSuccess = () => {
      hideSpinner();

      const onFailure = () => {
        destroyPaymentForm(paymentForm);
        setError(UNRECOVERABLE_ERROR);
        purchaseComplete(UNRECOVERABLE_ERROR);
      };

      onSubmit(data, onFailure);
    };

    const onError = ({ data }: any = {}) => {
      setError(data ? FORM_ERROR : UNRECOVERABLE_ERROR);
      purchaseComplete(UNRECOVERABLE_ERROR);
      hideSpinner();
    };

    try {
      paymentForm.onSubmit(onSuccess, onError);
    } catch {
      setError(UNRECOVERABLE_ERROR);
      purchaseComplete(UNRECOVERABLE_ERROR);
      hideSpinner();
    }
  };

  return {
    data_cc_card: { [PAYMENT_SELECTORS[PAYMENT_FIELDS.CARD]]: true },
    data_cc_cvv: { [PAYMENT_SELECTORS[PAYMENT_FIELDS.CVV]]: true },
    data_cc_exp: { [PAYMENT_SELECTORS[PAYMENT_FIELDS.EXPIRATION]]: true },
    data_cc_name: { [PAYMENT_SELECTORS[PAYMENT_FIELDS.NAME]]: true },
    initializePaymentJs,
    isPaymentJsDisabled: error === UNRECOVERABLE_ERROR,
    onPaymentJsSubmit,
    paymentJsError: error
  };
};
