/* eslint-disable max-len */
import 'src/components/layout/footer/index.scss';
import React, { useEffect, useState } from 'react';
import { useConfig, useTheme } from 'src/hooks';

const Footer = () => {
  const { backgroundColor } = useTheme('footer');
  const { footer: { links = [], copyright = '' } = {}, overrideStyles } = useConfig();

  // State to hold the sorted links
  const [ sortedLinks, setSortedLinks ] = useState([
    { href: '', label: 'Privacy Policy' },
    { href: '', label: 'Terms and Conditions (T&Cs)' },
    { href: '', label: 'Personal Electronics (T&Cs)' },
    { href: '', label: 'Personal Electronics T&Cs - Arizona' },
    { href: '', label: 'Personal Electronics Terms of Service' },
    { href: '', label: 'Personal Electronics T&Cs - Arizona (Spanish)' }
  ]);

  // useEffect to update sortedLinks when links change
  useEffect(() => {
    // Update href values based on links array
    links.forEach((link: { label: string, href: string }) => {
      switch (link.label) {
        case 'Privacy Policy':
          /* eslint-disable no-case-declarations */
          const privacyPolicyItem = sortedLinks.find(item => item.label === 'Privacy Policy');
          if (privacyPolicyItem) {
            privacyPolicyItem.href = link.href;
          }
          break;
        case 'Terms and Conditions (T&Cs)':
          /* eslint-disable no-case-declarations */
          const termsAndConditionsItem = sortedLinks.find(item => item.label === 'Terms and Conditions (T&Cs)');
          if (termsAndConditionsItem) {
            termsAndConditionsItem.href = link.href;
          }
          break;
        case 'Personal Electronics (T&Cs)':
          /* eslint-disable no-case-declarations */
          const personalElectronicsItem = sortedLinks.find(item => item.label === 'Personal Electronics (T&Cs)');
          if (personalElectronicsItem) {
            personalElectronicsItem.href = link.href;
          }
          break;
        case 'Personal Electronics T&Cs - Arizona':
          /* eslint-disable no-case-declarations */
          const personalElectronicsTCsArizonaItem = sortedLinks.find(item => item.label === 'Personal Electronics T&Cs - Arizona');
          if (personalElectronicsTCsArizonaItem) {
            personalElectronicsTCsArizonaItem.href = link.href;
          }
          break;
        case 'Personal Electronics Terms of Service':
          /* eslint-disable no-case-declarations */
          const personalElectronicsTermsofServicItem = sortedLinks.find(item => item.label === 'Personal Electronics Terms of Service');
          if (personalElectronicsTermsofServicItem) {
            personalElectronicsTermsofServicItem.href = link.href;
          }
          break;
        case 'Personal Electronics T&Cs - Arizona (Spanish)':
          /* eslint-disable no-case-declarations */
          const personalElectronicsTermsofServicSpanishItem = sortedLinks.find(item => item.label === 'Personal Electronics T&Cs - Arizona (Spanish)');
          if (personalElectronicsTermsofServicSpanishItem) {
            personalElectronicsTermsofServicSpanishItem.href = link.href;
          }
          break;
        default:
          break;
      }
    });

    // Update state with the modified sortedLinks
    setSortedLinks(sortedLinks);
  }, [ links ]); // Depend on links array for updates

  return (
    <footer style={{ backgroundColor }}>
      <div className='footer'>
        {copyright &&
          <span className='footer--copyright' style={overrideStyles?.footer}>
            {copyright.replace(/\d+/g, new Date().getFullYear())}
          </span>
        }
        <div className='footer--links'>
          {sortedLinks.map((link: any, index: number) => (
            <span key={link.label}>
              <a href={link.href} target='_blank' rel='noreferrer' className={`${overrideStyles?.hyperLinkClass}`} style={overrideStyles?.footer}>{link.label}</a>
              {index < sortedLinks.length - 1 && <span className='pipe'> |</span>}
            </span>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
