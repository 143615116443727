import 'src/components/atoms/logo/index.scss';
import Image from 'src/components/atoms/image';
import { Link } from 'gatsby';
import { useConfig } from 'src/hooks';

import React from 'react';

interface Props {
  src: string;
}

export default ({ src }: Props) => {
  const { homeIconRedirectUrl } = useConfig();

  return (
    <Link className='logo' to={homeIconRedirectUrl}>
      <Image src={src} alt='Site Logo' />
    </Link>
  );
};
