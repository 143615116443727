/* eslint-disable max-len */
import 'src/components/molecules/cart/index.scss';
import React, { forwardRef, useEffect } from 'react';
import { useApi, useCart, useModal } from 'src/hooks';
import Badge from 'src/components/atoms/badge';
import Button from 'src/components/atoms/button';
import Form from 'src/components/atoms/form';
import Icon from 'src/components/atoms/icon';
import Input from 'src/components/atoms/input';

import { PAGES } from 'src/constants';
import SelectQuantity from 'src/components/atoms/select-quantity';
import Separator from 'src/components/atoms/separator';
import { navigate } from 'gatsby';

import type { BillingFrequency } from 'src/context/cart/_types';
import type { EmptyFunction } from 'src/_types';
import type { Plan } from 'src/helpers/transformers/api/_types';

const { CHECK_OUT } = PAGES;

interface TitleProps {
  className?: string;
  hideBadge: EmptyFunction;
  isLocked?: boolean;
  shouldShowBadge: boolean;
}

interface ItemsProps {
  billingFrequency: BillingFrequency;
  plans: Array<Plan>;
  updateCart?: any;
  rate?: any;
}

interface TotalProps {
  discount?: number;
  isMobile?: boolean;
  rate?: any;
  total: number;
}

interface DiscountProps {
  discount: number;
  rate?: any;
}

const Title = ({ shouldShowBadge = false, className = '', isLocked = false, hideBadge }: TitleProps) => {
  useEffect(() => {
    shouldShowBadge && setTimeout(hideBadge, 3000);
  }, [ shouldShowBadge ]);

  return(
    <div className={`cart--title ${className}`}>
      <Icon type='cart'/>
      <span>Your cart</span>
      {!isLocked && <div className='cart--title--badge' style={{ visibility: shouldShowBadge ? 'visible' : 'hidden' }}>
        <Badge label='Cart updated!'/>
      </div>}
    </div>
  );
};

const Items = ({ plans, updateCart }: ItemsProps) => (
  <>
    {plans.map((plan) => (
      <div key={plan.id}>
        <div className='cart--item'>
          <div className='cart--item--label'>
            <span className='cart--item--label--group'>
              <Icon type='trash' onClick={() => updateCart({ amount: 0, plan })} className='cart--items--item--label--group--icon' height='20'/>
              <span className='cart--item--label--group--name'>{plan.name}</span>
            </span>
            <span>
              <span>{ plan?.quantity && plan?.quantity > 0 ? `$${(plan?.quantity * plan?.price.monthlyAmount).toFixed(2)}/mo` : plan?.price.formattedAmount }</span>
            </span>
          </div>
          <div className='cart--item--select'>
            Qty:
            <SelectQuantity value={plan.quantity} max={plan.maxQuantity} onChange={(amount) => updateCart({ amount, plan })}/>
          </div>
        </div>
        <Separator />
      </div>
    ))}
  </>
);

const LockedItems = ({ plans }: ItemsProps) => (
  <>
    {plans.map((plan) => (
      <div key={plan.id}>
        <div className='cart-locked--item'>
          <div className='cart-locked--item--label'>
            <span className='cart-locked--item--label--group'>
              <span className='cart-locked--item--label--group--qty'>{plan.quantity} x </span>
              <span className='cart-locked--item--label--group--name'>{plan.name}</span>
            </span>
            <span>
              <span>{ plan?.quantity && plan?.quantity > 0 ? `$${(plan?.quantity * plan?.price.monthlyAmount).toFixed(2)}/mo` : plan?.price.formattedAmount }</span>
            </span>
          </div>
        </div>
        <Separator />
      </div>
    ))}
  </>
);

const Discount = ({ discount, rate }: DiscountProps) => (
  <div className='cart--discount'>
    <span>Discount</span>
    <span>
      <span className='cart--discount--amount'>-${discount}</span>
      <span>{rate}</span>
    </span>
  </div>
);

const Total = ({ isMobile = false, rate, total }: TotalProps) => (
  <div className='cart--total'>
    {!isMobile && <span>Total</span>}
    <span>
      <span className='cart--total--amount'>${total}</span>
      <span className='cart--total--rate'>{rate}</span>
    </span>
  </div>
);

const DiscountForm = () => {
  const { applyDiscount } = useApi();

  return (
    <>
      <Separator />
      <div className='cart--discount-form'>
        <Form
          id='DISCOUNT_CODE_FORM'
          buttonLabel='Apply'
          onSubmit={applyDiscount}
        >
          <Input name='promoCode' label='Discount code' type='text' secondaryLabel='Optional' maxLength={10}/>
        </Form>
      </div>
    </>
  );
};

export const Cart = ({ hideDiscount = false, isLocked, withButton }: any) => {
  const { billingFrequency, shouldEnableDiscountForm, shouldShowBadge, cartTotal, discount, plansInCart, rate, isCartEmpty, hideBadge, updateCart } = useCart();
  const isDisabled = isCartEmpty;

  return( !isLocked ?
    <div className='cart'>
      <Title shouldShowBadge={shouldShowBadge} hideBadge={hideBadge}/>
      <Separator />
      <Items billingFrequency={billingFrequency} plans={plansInCart} updateCart={updateCart}/>
      <Total total={cartTotal} rate={rate}/>
      <Separator />
      {withButton && <Button
        className='cart--button'
        label='Continue to checkout'
        theme={isDisabled ? 'gray' : 'primary'}
        onClick={() => {navigate(CHECK_OUT); sessionStorage.removeItem('serviceInfo');sessionStorage.removeItem('billingInfo');}} disabled={isDisabled}
      />}
    </div> :
    <div className='cart-locked'>
      <div>
        <Title shouldShowBadge={shouldShowBadge} hideBadge={hideBadge} isLocked={true}/>
        <Separator />
        <LockedItems billingFrequency={billingFrequency} plans={plansInCart} />
        {discount && <Discount discount={discount} rate={rate}/>}
        <Total total={cartTotal} rate={rate}/>
        {shouldEnableDiscountForm && !hideDiscount && <DiscountForm />}
      </div>
    </div>
  );
};

export const MobileCart = forwardRef<HTMLDivElement, any>(({ isLocked, withButton }: any, ref) => {
  const { shouldEnableDiscountForm, shouldShowBadge, cartTotal, rate, isCartEmpty, hideBadge } = useCart() as any;
  const { showModal } = useModal();
  const isDisabled = isCartEmpty;

  return (
    <div className='mobile-cart' ref={ref}>
      <div className='mobile-cart--main' onClick={() => showModal({ innerContent: <Cart isLocked={isLocked} hideDiscount={true} withButton={withButton} /> })}>
        <div className='mobile-cart--main--rectangle'></div>
        <div className='mobile-cart--main--row'>
          <Title className='mobile-cart--main--row--title' shouldShowBadge={shouldShowBadge} hideBadge={hideBadge}/>
          <Total total={cartTotal} rate={rate} isMobile/>
        </div>
      </div>
      {withButton && <Button
        className='mobile-cart--button'
        label='Continue to checkout'
        theme={isDisabled ? 'gray' : 'primary'}
        onClick={() => {navigate(CHECK_OUT); sessionStorage.removeItem('serviceInfo');sessionStorage.removeItem('billingInfo');}} disabled={isDisabled}
      />}
      {shouldEnableDiscountForm && <DiscountForm />}
    </div>
  );
});

export default Cart;
