/* eslint-disable max-len */
import 'src/components/atoms/form/index.scss';
import React, { Fragment, createElement, useEffect } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'src/components/atoms/button';
import Icon from 'src/components/atoms/icon';
import { pushMessageViewEvent } from 'plugins/custom-plugin-adobe-launch';
import { useFormData } from 'src/hooks';

type FormValues<T> = {
  [key in keyof T]: string;
};

interface Props<T> {
  buttonLabel: string;
  children: any;
  defaultValues?: any;
  externalError?: string;
  id?: string;
  onSubmit: SubmitHandler<FormValues<T>>;
  validation?: (getValues: any) => {
    [key in keyof FormValues<T>]?: RegisterOptions
  };
}

const get = <T extends object, U extends keyof T>(obj: T, key: U) => obj[key];

export default <T extends object>({
  buttonLabel = 'Submit',
  children,
  defaultValues,
  externalError = '',
  id = '',
  onSubmit,
  validation = () => ({})
}: Props<T>) => {
  const { saveFormData, ...formData } = useFormData();

  const savedValues = formData[id];

  const {
    formState: { isDirty, errors },
    getValues,
    handleSubmit,
    register,
    reset
  } = useForm<FormValues<T>>({ defaultValues: { ...defaultValues, ...savedValues }, shouldUnregister: true });

  const errorCount = Object.keys(errors).length;
  const hasError = !!errorCount || !!externalError;

  const allFieldsPopulated = Object.values(getValues()).every(Boolean);
  const hasSavedFormData = Object.keys(formData[id] ?? {}).length;

  const canSubmit = !hasError && (isDirty || hasSavedFormData || allFieldsPopulated);
  const errorMessage = errorCount > 0 ? Object.values(errors)[0]?.message : externalError;

  if (hasError) {
    pushMessageViewEvent({
      event: 'messageView',
      messages: {
        error: errorMessage
      }
    });
  }

  useEffect(() => reset(savedValues), [ savedValues ]);

  const createFormElement = (element: any, index: number) => {
    const { type, props, props: { name = '' } = {} } = element;

    return name
      ? createElement(type, {
        ...props,
        formProps: { ...register(name, get(validation(getValues), name)), error: get(errors, name) },
        key: `${name}-${index}`
      })
      : element;
  };

  const createFormElements = (children: any) => {
    const elements = ([] as any).concat(children).flat();

    return elements.map((element: any, index: number) => {
      const { type, props } = element;

      if ([ Fragment, 'div', 'span' ].includes(type) && props.children) {
        const children = ([] as any).concat(props.children);

        return (<div {...props} key={index}>{createFormElements(children)}</div>);
      } else {
        return createFormElement(element, index);
      }
    });
  };

  const submitFn = (data: FormValues<T>) => {
    saveFormData({ data, id });
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submitFn)} noValidate role='form' id={id}>
      {createFormElements(children)}

      <div className='form--button'>
        <Button theme={canSubmit ? 'primary' : 'gray'} label={buttonLabel} type='submit' disabled={!canSubmit} />

        {hasError &&
          <span className='form--button--error'>
            <Icon className='form--button--error--icon' type='error' width='24' height='24' />
            {errorCount ? `Please fix the (${errorCount}) error(s) above` : ''}
            {!errorCount && externalError || ''}
          </span>
        }
      </div>
    </form>
  );
};
