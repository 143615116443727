module.exports = [{
      plugin: require('../plugins/custom-plugin-page-prefetch/gatsby-browser.tsx'),
      options: {"plugins":[],"routes":["/","/shopping","/check-out","/confirmation"]},
    },{
      plugin: require('../plugins/custom-plugin-adobe-launch/gatsby-browser.tsx'),
      options: {"plugins":[],"pageViewData":{"/check-out":{"pageDetail":"checkout"},"/confirmation":{"pageDetail":"confirm"},"/":{"pageDetail":"userinfo","pageExtraDetail":"enterzip"},"/shopping":{"pageDetail":"plans","pageExtraDetail":"productchart"}},"scriptUrl":"https://assets.adobedtm.com/bdc402f2525d/71712763d7bc/launch-6c79600990f9.min.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
